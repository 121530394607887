.slider {
    height: 45vw;
    margin-bottom: 10vw;
    width: 70%;

}

@media (min-width: 3000px) {
    .slider {
        width: 90%;
        height: 32vw
    }
}

@media (max-width: 1200px) {
    .slider {
        width: 80%;
        height: 54vw
    }
}

@media (max-width: 992px) {
    .slider {
        width: 100%;
        height: 70vw
    }
}

@media (max-width: 576px) {
    .slider {
        width: 100%;
        height: 110vw
    }
}

.slider-footer {
    bottom: 1rem;
    left: 50%;
    translate: -50% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.footer-line {
    width: 100%;
    height: 1px;
    background-color: var(--secondary-color);
    border: 0;
    padding: 0 !important;
}

.footer-labels {
    height: 4rem;
    width: 100%;
}

.footer-label {
    font-size: 2.25rem !important;
    font-weight: 600 !important;
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &.animated {
        opacity: 0;
        animation: none;
    }
}

@media (max-width: 1200px) {
    .footer-label {
        font-size: 1.7rem !important;
        width: fit-content;
        text-wrap: nowrap;
    }
}

@media (max-width: 992px) {
    .footer-label {
        font-size: 1rem !important;
        width: fit-content;
    }
}

.footer-label--active {
    opacity: 1 !important;
}

.bullet-container {
    gap: 0.6rem;
    display: flex;
}

.bullet {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--bullet-color);
    border-radius: 9999px;
    border: 0;
    padding: 0 !important;
    transition: all 0.2s ease-in-out;
}

.bullet:hover {
    background-color: var(--bullet-color-active) !important;
}

.bullet--active {
    background-color: var(--bullet-color-active) !important;
}

.slide {
    opacity: 0.7;
    left: 50%;
    top: 40%;
    width: 70%;
    translate: -50% -50%;
    position: absolute;
}

@media (max-width: 992px) {
    .slide {
        width: 70%;
    }
}

.slide--animated {
    transition: all 0.5s ease-in-out;
}

.slide--prev {
    transform: translateX(-130%);
}

.slide--next {
    transform: translateX(130%);
}

.slide--prev2 {
    transform: translateX(-250%);
}

.slide--next2 {
    transform: translateX(250%);
}

.slide--current {
    margin-left: auto;
    margin-right: auto;
    width: 80% !important;
    opacity: 1 !important;
}

.switch {
    width: 3rem;
    height: 3rem;
    border-radius: 9999px !important;
    position: absolute;
    top: 40%;
    translate: 0 -50%;
    align-items: center;
    justify-content: center;
    display: flex !important;
}

.switch-next {
    right: 1rem;
}

.switch-prev {
    left: 1rem;
}

@media (max-width: 992px) {
    .slide--current {
        width: 75% !important;
    }

    .switch {
        height: 2rem;
        width: 2rem;
        padding: 0 !important;

        svg {
            height: 14px;
            width: 14px;
        }
    }

    .switch-next {
        right: 0.25rem;
    }

    .switch-prev {
        left: 0.25rem;
    }
}

@media (max-width: 576px) {
    .switch {
        height: 1.3rem;
        width: 1.3rem;
        padding: 0 !important;

        svg {
            height: 10px;
            width: 10px;
        }
    }
}