:root {
    --primary-color: #0ea5e9;
    --secondary-color: #e2e2e2;
    --bullet-color: #c7c7c7;
    --bullet-color-active: #222;
    --main-anim-duration: 1s;
    --header-font-size: 5.5vw;
}

body {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

@media (min-width: 2000px) {
    main {
        margin-left: 10vw;
        margin-right: 10vw;
        position: relative;
        margin-top: 0;
    }
}

@media (min-width: 3000px) {
    main {
        margin-left: 20vw;
        margin-right: 20vw;
    }
}


.text-header {
    font-family: Arial, Arial, sans-serif;
    font-size: var(--header-font-size);
    color: var(--primary-color);
    font-weight: 700;
    letter-spacing: -0.4rem;
    line-height: 0.8;
    text-wrap: wrap;
    z-index: 1;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .text-header {
        font-size: calc(var(--header-font-size) * 0.8);
        letter-spacing: -0.05vw;
    }
}

@media (min-width: 3000px) {
    .text-header {
        font-size: calc(var(--header-font-size) * 0.4);
        letter-spacing: -0.05vw;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .text-header {
        font-size: calc(var(--header-font-size) * 1);
        letter-spacing: -0.05vw;
    }
}

@media (max-width: 992px) {
    .text-header {
        font-size: calc(var(--header-font-size) * 1.6);
        letter-spacing: -0.1rem;
        line-height: 0.75;
    }
}

@media (max-width: 576px) {
    .text-header {
        font-size: calc(var(--header-font-size) * 2);
    }
}

.text-header2 {
    font-family: "Source Code Pro", Arial, sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1.21;
    z-index: 1;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

p {
    font-size: 1.2vw;
    font-family: 'Source Code Pro', Arial, sans-serif;
    font-weight: 400;
    line-height: 1.35;
    color: var(--primary-color);
    margin-bottom: 0 !important;
    z-index: 1;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .text-header2 {
        font-size: 1.3vw !important;
    }

    p {
        font-size: 1vw;
    }
}

@media (min-width: 3000px) {
    .text-header2 {
        font-size: 1vw !important;
    }

    p {
        font-size: 0.8vw;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .text-header2 {
        font-size: 1.3rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .text-header2 {
        font-size: 1.3rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .text-header2 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.95rem;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes main-anim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dash {
    width: 10rem;
    height: 0.8rem;
    margin-left: 6rem;
    background-color: var(--primary-color);
    transform: translateY(-50%);
    margin-top: calc(var(--header-font-size) / 2);
    transform-origin: left;
    opacity: 0;

    &.animated {
        animation: anim-dash var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .dash {
        margin-left: 5rem;
        margin-top: calc(var(--header-font-size) * 0.4);
        height: 0.9rem !important;
        width: 9rem;
    }
}

@media (min-width: 3000px) {
    .dash {
        margin-left: 4rem;
        margin-top: calc(var(--header-font-size) * 0.17);
        height: 0.5rem !important;
        width: 7rem;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .dash {
        margin-left: 5rem;
        margin-top: calc(var(--header-font-size) * 0.45);
        height: 0.6rem !important;
        width: 8rem;
    }
}

@media (max-width: 992px) {
    .dash {
        margin-left: 2rem;
        height: 0.5rem !important;
        width: 7rem;
        margin-top: calc(var(--header-font-size) / 1.7);
    }
}

@media (max-width: 576px) {
    .dash {
        margin-left: 0rem !important;
    }
}

.page-header {
    width: fit-content;
    margin-top: 8vw;
    margin-bottom: 7vw;
}
