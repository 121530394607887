.documents-text-background {
    font-family: 'Source Code Pro', Arial, sans-serif;
    color: var(--secondary-color);
    z-index: 0 !important;
    font-weight: 700;
    line-height: 0.8;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

.documents-section {
    gap: 4rem;
    margin-bottom: 5vw;

    .text-header2 {
        font-size: 1.7vw
    }
}

.documents-link {
    position: absolute;
    left: 50%;
}

.doc-parts {
    left: 57%;

    .documents-text-background {
        font-size: 11vw;
    }
}

@media (max-width: 992px) {
    .documents-section {
        margin-bottom: 15vw;
        gap: 2rem;

        .text-header2 {
            font-size: 3vw;
        }
    }

    .parts {
        left: 49%;

        .documents-text-background {
            font-size: 14vw;
        }
    }

    .documents-link {
        left: 120%;
    }
}

@media (max-width: 576px) {
    #doc_dash_1 {
        margin-top: 5rem;
    }

    .documents-section {
        margin-bottom: 15vw;

        .text-header2 {
            font-size: 5vw;
        }
    }

    .documents-link {
        position: absolute;
        right: 2%;
        bottom: 0;
        text-align: right;
        translate: 0 120%;
        left: auto;
    }

    .parts {
        left: 3%;

        .documents-text-background {
            font-size: 19vw;
        }
    }
}

@media (min-width: 2000px) {
    .documents-text-background {
        font-size: 7vw !important;
    }
}

@media (min-width: 3000px) {
    .documents-text-background {
        font-size: 3.6vw !important;
    }
}