.ufa-main {
    margin-top: 6vw;
}

@media (min-width: 992px) {
    .ufa-main {
        p {
            font-size: 1.1vw !important;
        }
    }
}

@media (max-width: 576px) {
    .ufa-dash {
        position: absolute;
        top: 18vw;
    }
}

.ufa-img-container {
    margin-top: 2vw;
    margin-bottom: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
    .ufa-img-container {
        margin-top: 8vw;
        margin-bottom: 8vw;

        img {
            width: 100%;
        }
    }
}

@media (max-width: 576px) {
    .ufa-img-container {
        margin-top: 18vw;
        margin-bottom: 18vw;

        img {
            width: 100%;
        }
    }
}

.ufa-p-from-4 {
    margin-bottom: 2vw !important;
}

@media (max-width: 576px) {
    .ufa-p-from-4 {
        margin-bottom: 12vw !important;
    }
}

@media (max-width: 576px) {
    .ufa-header {
        position: relative;
        margin-top: 16vw;
        margin-bottom: 28vw;
    }
}

@media (min-width: 3000px) {
    .ufa-header {
        margin-top: 2vw !important;
        margin-bottom: 2vw !important;
    }

    .ufa-main {
        margin-left: 30% !important;
        width: 40% !important;

        p {
            font-size: 0.8vw !important;
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .ufa-main p {
        font-size: 1.4vw !important;
    }
}