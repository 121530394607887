.video-text-background {
    font-family: 'Arial', Arial, sans-serif;
    color: var(--secondary-color);
    z-index: 0 !important;
    font-weight: 600;
    line-height: 0.8;
    opacity: 0;
    font-size: 17vw;
    z-index: 10;
    position: relative;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

.video-section {
    margin-top: 16rem;
    margin-bottom: 5rem;
}

.video-first-row {
    gap: 3.5vw;
}

.video-frame {
    width: 32vw;
    height: 17vw;
}

.vid-parts {
    display: flex;
    flex-direction: row;
    left: -3.5vw;
    top: -5vw;
}

.video-text-background.part1 {
    margin-left: 0;
}

.video-text-background.part2 {
    margin-left: 26vw;
}

@media (min-width: 576px) and (max-width: 992px) {
    .vid-parts {
        left: -6vw;
        flex-direction: column;
    }

    .video-text-background.part2 {
        margin-left: 0;
        margin-top: 28vw;
    }

    .video-first-row {
        gap: 8vw;
    }

    .video-frame {
        width: 60vw;
        height: 35vw;
        z-index: 1;
    }

    .video-header {
        margin-left: 40%;
        width: fit-content;
    }
}

@media (max-width: 992px) {
    .video-section {
        margin-top: 4rem;
        margin-bottom: 5rem;
    }
}

@media (max-width: 576px) {
    .vid-parts {
        right: 0;
        left: auto;
        flex-direction: column;
        top: -3.2rem;
        z-index: 0;
    }

    .video-first-row-second-frame {
        margin-top: 3rem;
    }

    .video-text-background.part1 {
        font-size: 4rem;
    }

    .video-text-background.part2 {
        font-size: 4rem;
        margin-left: auto;
        margin-top: calc(50vw + 0.7rem);
    }

    #vid_dash_1 {
        margin-top: 5rem;
    }

    .video-frame {
        width: 92vw;
        height: 50vw;
        z-index: 1;
    }
}

.vid-video-player-container {
    width: 25vw;
    height: 15vw;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
    }
}

@media (min-width: 2000px) {
    .video-frame {
        width: 25vw;
        height: 13.5vw;
    }

    .video-text-background.part2 {
        margin-left: 19vw;
        margin-top: 0;
    }
}

@media (min-width: 3000px) {
    .video-frame {
        width: 20vw;
        height: 10.8vw;
    }

    .video-text-background.part2 {
        margin-left: 14vw;
        margin-top: 0;
    }
}