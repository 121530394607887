.player-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 11rem;
    width: 8rem;
    transition: all 0.2s ease-in-out;
    scale: 1;
    border-radius: 0.5rem;
    box-shadow: #22222266 0 0 1.5rem 0.2rem;

    &.hidden {
        display: none !important;
    }

    &:hover {
        scale: 1.05;

        .player-btn {
            visibility: visible;
        }
    }
}

.player-container.opened {
    width: 32rem;
    height: 18rem;

    iframe {
        height: 100%;
        width: 100%;
        pointer-events: all !important;
    }

    &:hover {
        scale: 1 !important;
    }
}

@media (min-width: 2000px) {
    .player-container {
        height: 11vw;
        width: 8vw;
    }

    .player-container.opened {
        width: 30vw;
        height: 17vw;
    }
}

@media (max-width: 576px) {
    .player-container.opened {
        width: 90vw;
        height: 45vw;
    }
}

.player-btn {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    translate: 50% -50%;
    width: 1.35rem;
    height: 1.35rem;
    border-radius: 9999px;
    background-color: #111 !important;
    border: 0;
    transition: all 0.15s ease-in-out;
    padding: 0;
    margin: 0;

    &:hover {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #444;
    }

    &:active {
        transition: all 75ms ease-in-out;
        width: 1.4rem;
        height: 1.4rem;
    }
}

@media (min-width: 2000px) {
    .player-btn {
        width: 1vw !important;
        height: 1vw !important;

        &:hover {
            width: 1.1vw !important;
            height: 1.1vw !important;
        }

        &:active {
            width: 0.9vw !important;
            height: 0.9vw !important;
        }
    }
}

@media (max-width: 992px) {
    .player-btn {
        visibility: visible;
    }
}

@media (pointer: none), (pointer: coarse) {
    .player-btn {
        visibility: visible;
    }
}

.player-container.opened .player-btn {
    width: 1.55rem;
    height: 1.55rem;
    visibility: visible;

    &:hover {
        width: 1.7rem;
        height: 1.7rem;
    }
}

.player-minus {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 40%;
    height: 0.1rem;
    background-color: #fff !important;
}

.player-cross {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 60%;
    height: 0.1rem;
    transform-origin: center;
    background-color: #fff !important;
}

.player-btn-more {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 9999px;
    background-color: #1f5bff;
    margin: 1.5rem;
    padding: 1rem 0;
    border: 0;
    animation: btn-more-anim 0.3s ease-in-out forwards;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .player-btn-more {
        margin: 2rem;
        padding: 1rem 0;

        h2 a {
            font-size: 0.7vw !important;
        }
    }
}

@media (min-width: 3000px) {
    .player-btn-more {
        margin: 3rem;
        padding: 1.5rem 0;

        h2 a {
            font-size: 0.7vw !important;
        }
    }
}

@keyframes btn-more-anim {
    from {
        bottom: -4rem;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

.mini__video-player {
    border-radius: 0.5rem;
    overflow: hidden;
    pointer-events: none;
    height: 100%;
    width: 100%;
}
