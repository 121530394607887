#contacts_dash_1 {
    margin-left: 0;
    margin-right: 4rem;
}

@media (max-width: 992px) {
    #contacts_dash_1 {
        top: 3rem;
        position: absolute;
    }
}

@media (max-width: 576px) {
    #contacts_dash_1 {
        top: 10rem;
        position: absolute;
    }
}

.contacts-gray-block {
    background-color: #f3f3f3;
    width: 100%;
    height: 33vw;
    margin-left: 4%;
}

@media all and (max-width: 1200px) {
    .contacts-gray-block {
        height: 55vw;
    }
}

@media all and (max-width: 992px) {
    .contacts-gray-block {
        height: 60vw;
    }
}

@media (max-width: 576px) {
    .contacts-gray-block {
        visibility: hidden;
    }
}

.contacts-header {
    margin-left: 15%;
}

.contacts-section {
    margin-top: 3rem;
    align-items: start;
    display: flex;
    flex-direction: column;
}

.contacts-link {
    margin-top: 1rem;
}

.contacts-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

@media (min-width: 2000px) {
    .contacts-gray-block {
        height: 40.4vw;
    }
}

@media (min-width: 3000px) {
    .contacts-gray-block {
        height: 27vw;
    }
}

@media (max-width: 576px) {
    .contacts-footer {
        padding: 0 2rem;
    }
}

.contacts-footer-line {
    width: 100%;
    height: 0.8rem;
    background-color: var(--primary-color);
}

.contacts-footer-brand {
    position: absolute;
    top: -10vw;
    left: 2rem;

    .navbar-brand.text-color {
        letter-spacing: 0.3vw;
    }
}

@media (min-width: 576px) {
    .contacts-page-header {
        position: relative;
    }
}
