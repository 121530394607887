.download-section-1 {
    gap: 5vw;
    padding-bottom: 5.5vw;
}

.download-section {
    display: flex;
    flex-direction: column;
    gap: 4vw;
    margin-top: 4vw;
    position: relative;
}

.download-section-last {
    margin-bottom: 5vw;
}

.download-header {
    margin-bottom: 10vw;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .download-header {
        margin-bottom: 11vw;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .download-header {
        margin-left: 15% !important;
    }
}

.download-long-dash {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.download-text-background {
    font-family: "Source Code Pro", Arial, sans-serif;
    color: var(--secondary-color);
    z-index: 0 !important;
    font-weight: 700;
    line-height: 0.8;
    opacity: 0;
    font-size: 11.5vw;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (min-width: 2000px) {
    .download-text-background {
        font-size: 9.5vw;
    }
}

@media (min-width: 3000px) {
    .download-text-background {
        font-size: 8vw;
    }
}

@media (max-width: 576px) {
    .download-text-background {
        font-size: 20vw;
    }
}

.download-part-1-2 {
    bottom: 2vw;
    right: -15vw;
}

.download-section-back {
    position: absolute;
    top: 0;
}

.download-text-background.part3 {
    right: 13vw;
}

.download-text-background.part4 {
    right: 0vw;
}

.download-text-background.part5 {
    right: -4vw;
}

.download-text-background.part6 {
    right: 12vw;
}

@media (min-width: 3000px) {
    .download-text-background.part3 {
        right: 7vw;
    }

    .download-text-background.part4 {
        right: -2vw;
    }

    .download-text-background.part5 {
        right: -3vw;
    }

    .download-text-background.part6 {
        right: 8vw;
    }
}

@media (max-width: 992px) {
    .download-part-1-2 {
        right: 0;
    }

    .download-text-background.part3 {
        right: 13vw;
    }

    .download-text-background.part4 {
        right: 0vw;
    }

    .download-text-background.part5 {
        right: 0vw;
    }

    .download-text-background.part6 {
        right: 12vw;
    }
}

@media (max-width: 576px) {
    .download-part-1-2 {
        top: 0;
        left: 0;
        right: auto;
    }

    .download-section-back {
        left: 0;
        right: auto;
    }
}

@media (max-width: 576px) {
    .download-dash {
        margin-top: 15vw;
    }
}