.download-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 576px) {
    .download-row {
        justify-content: space-between;
        position: relative;
    }

    .download-row-link {
        position: absolute;
        right: 0;
        bottom: 0;
        translate: 0 -100%;
    }

    .download-row-label {
        margin-bottom: 4rem;
    }
}