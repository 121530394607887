.benefit-number {
    padding: 2.4rem;
}

.benefit-paragraph {
    width: 100%;
    padding: 2.4rem;
    top: 9rem;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .benefit-paragraph {
        top: 50%;
        padding: 1rem;
    }
}

@media (max-width: 992px) {
    .benefit-number {
        padding: 1rem;
    }

    .benefit-paragraph {
        top: 35%;
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .benefit-number {
        padding: 1rem;
    }

    .benefit-paragraph {
        top: 35%;
        padding: 1rem;

        h2 {
            font-size: 1rem !important;
        }

        p {
            font-size: 0.7rem;
        }
    }
}

@media (min-width: 3000px) {
    .benefit-paragraph {
        top: 35% !important;

        .text-header2 {
            font-size: 0.9vw !important;
        }

        p {
            font-size: 0.7vw !important;
        }
    }
}
