.circle {
    border-radius: 9999px;
    background-color: var(--primary-color);
    position: relative;
    height: 3rem;
    width: 3rem;
    margin-bottom: -1.4rem;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (min-width: 2000px) {
    .circle {
        height: 5rem;
        width: 5rem;
        margin-bottom: -2.4rem;
    }
}

@media (max-width: 768px) {
    .circle {
        height: 1.75rem;
        width: 1.75rem;
        margin-bottom: -0.8rem;
    }
}

.circle-cover {
    background-color: #FFFFFF;
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}