.menu-header {
    height: 0.5rem;
    color: rgb(14, 165, 233);
    background: rgb(14, 165, 233);
}

@media (min-width: 2000px) {
    .menu-header {
        height: 0.5rem;
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media (min-width: 3000px) {
    .menu-header {
        height: 0.5rem;
        margin-left: 16vw;
        margin-right: 16vw;
    }
}

.menu-header2 {
    height: 4vw;
}

.menu-text {
    z-index: 10;
    gap: 1.8vw !important;
}

.menu-text li a {
    color: rgb(14, 165, 233);
    font-family: "Source Code Pro", Arial, sans-serif;
    /*font-size: 12px;*/
    /*font-weight: 700;*/
}

.navbar-brand.text-color {
    color: rgb(14, 165, 233);
    font-family: "Source Code Pro", Arial, sans-serif;
    z-index: 10;
    font-size: 1.4vw;
}

nav.scrolled {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    animation: nav-scroll-anim 0.3s ease-in-out;
    padding-left: 16vw;
    padding-right: 16vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 2000px) {
    nav.navbar {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    nav.scrolled {
        padding-left: 16vw;
        padding-right: 16vw;
    }
}

@media (min-width: 3000px) {
    nav.navbar {
        padding-left: 18vw;
        padding-right: 18vw;
    }

    nav.scrolled {
        padding-left: 20vw;
        padding-right: 20vw;
    }
}

@keyframes nav-scroll-anim {
    from {
        top: -3rem;
    }
    to {
        top: 0;
    }
}

nav.unscrolled {
    animation: nav-unscroll-anim 0.2s ease-in-out;
    top: -3rem;
}

@keyframes nav-unscroll-anim {
    from {
        top: 0;
    }
    to {
        top: -3rem;
    }
}

.nav-link {
    font-size: 1vw !important;
    font-weight: 700 !important;
}

.nav-item.active a.lang {
    color: var(--primary-color) !important;
}

@media (min-width: 992px) {
    .nav-link:not(.lang):not(.download) {
        position: relative;

        &::before {
            content: ">";
            color: var(--primary-color);
            position: absolute;
            left: -1.5rem;
            top: 50%;
            translate: 0 -50%;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }

    .nav-link:not(.lang):not(.download):hover {
        color: var(--primary-color) !important;

        &::before {
            left: -0.8rem;
            opacity: 1;
        }
    }

    .nav-link.lang {
        position: relative;

        &::before {
            content: "";
            background-color: var(--primary-color);
            position: absolute;
            left: 10%;
            right: 10%;
            bottom: 0;
            opacity: 0;
            height: 0.15rem;
            transition: opacity 0.2s ease-in-out;
        }
    }

    .nav-link.lang:hover {
        color: var(--primary-color) !important;

        &::before {
            opacity: 1;
        }
    }
}

@media (min-width: 3000px) {
    .nav-link:not(.lang):not(.download)::before {
        left: -3rem;
    }

    .nav-link:not(.lang):not(.download):hover::before {
        left: -1.6rem;
    }
}

nav.scrolled .nav-link {
    font-size: 0.75vw !important;
}

nav.scrolled .navbar-brand.text-color {
    font-size: 1vw !important;
}

nav.scrolled .menu-text {
    gap: 1.4vw !important;
}

.nav-link.download {
    /*color: rgb(255, 255, 255);*/
    border-radius: 9999px;
    /*background: rgb(150,150,150);*/
    background: rgb(14, 165, 233);
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.nav-link.download:hover {
    background: rgb(25, 178, 246);
}

.nav.mobile {
    display: none;
    flex-direction: row;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    nav.scrolled {
        .navbar-brand.text-color {
            font-size: 1.4vw !important;
        }

        .menu-text {
        }

        .nav {
            .nav-link {
                font-size: 1vw !important;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .menu-header2 {
        height: 2.5rem;
    }

    .navbar-brand.text-color {
        font-size: 1.7rem !important;
    }

    .nav.desktop {
        display: flex;
        flex-direction: row;
        gap: 1.25rem !important;

        .nav-link {
            font-size: 1rem !important;
        }
    }

    nav.scrolled {
        padding-left: 1.5rem;
        padding-right: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        .navbar-brand.text-color {
            font-size: 1.7rem !important;
        }

        .menu-text {
            gap: 1.5rem !important;
        }

        .nav {
            .nav-link {
                font-size: 0.95rem !important;
            }
        }
    }
}

@media (max-width: 992px) {
    .menu-header2 {
        height: 2.5rem;
    }

    .navbar-brand.text-color {
        font-size: 1.3rem !important;
    }

    .nav.desktop {
        display: flex;
        flex-direction: row;
        gap: 1.25rem !important;

        .nav-link {
            font-size: 0.8rem !important;
        }
    }

    nav.scrolled {
        padding-left: 1.5rem;
        padding-right: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        .navbar-brand.text-color {
            font-size: 1.3rem !important;
        }

        .menu-text {
            gap: 1.5rem !important;
        }

        .nav {
            .nav-link {
                font-size: 0.75rem !important;
            }
        }
    }
}

@media (max-width: 576px) {
    .navbar-brand.text-color {
        font-size: 5vw !important;
    }

    .nav.mobile {
        display: flex;
        align-items: center;
        gap: 8vw !important;

        .nav-link {
            font-size: 4vw !important;
        }
    }

    .nav.desktop {
        display: none;
    }

    nav.scrolled {
        padding-left: 2.5rem;
        padding-right: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        .navbar-brand.text-color {
            font-size: 5vw !important;
        }

        .menu-text {
            gap: 1.5rem !important;
        }

        .nav .nav-link {
            font-size: 4vw !important;
        }
    }

    .menu-header2 {
        height: 1rem;
    }

    .menu-header {
        height: 0.3rem;
    }
}
