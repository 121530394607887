.home-text-background {
    font-family: Arial, Arial, sans-serif;
    color: var(--secondary-color);
    z-index: 0 !important;
    font-weight: 600;
    line-height: 0.8;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

@media (max-width: 992px) {
    #dash_3 {
        top: 3rem;
    }

    #dash_4 {
        margin-left: 0 !important;
    }

    #dash_5 {
        order: 2;
    }

    #header_6 {
        order: 1;
    }

    #dash_6 {
        position: absolute;
        top: 8rem;
        height: 0.7rem;
    }
}

@media (max-width: 576px) {
    #dash_1 {
        position: absolute;
        left: 0;
        top: 5rem;
    }

    #dash_3 {
        top: 4rem;
    }

    #dash_6 {
        top: 12rem;
    }
}

@media (max-width: 992px) {
    #dash_6 {
        margin-left: 0 !important;
    }
}

@keyframes anim-dash {
    from {
        scale: 0 1;
    }
    to {
        scale: 1 1;
    }
}

#dash_6 {
    margin-left: 4rem;
    margin-top: 1.8rem;
    height: 0.7rem;
}

.home-text-background.part1 {
    font-size: 17vw;
    line-height: 1;
    letter-spacing: -0.5rem;
}

.home-text-background.part2 {
    font-size: 8.5vw;
    margin-top: -0.5rem;
    letter-spacing: -0.5rem;
}

.back-part-3-4 {
    top: 4rem;
    text-align: right;
}

.home-text-background.part3 {
    font-size: 5.4vw;
}

.home-text-background.part4 {
    font-size: 10.6vw;
}

.home-text-background.part5 {
    font-size: 16vw;
    margin-left: -4%;
}

.home-text-background.part6 {
    font-size: 16vw;
    margin-left: 44%;
}

.back-part-5-6 {
    left: 0;
    top: 27vw;
}

.home-text-background.part7 {
    font-size: 13vw;
    line-height: 0.6;
    letter-spacing: 0.3vw;
    font-family: 'Source Code Pro', Arial, sans-serif;
    font-weight: 700;
    top: 7%;
    left: 10%;
}

.home-text-background.part8 {
    font-size: 13vw;
    line-height: 0.6;
    letter-spacing: 0.3vw;
    font-family: 'Source Code Pro', Arial, sans-serif;
    font-weight: 700;
    top: 48%;
    right: 3%;
}

.home-text-background.part9 {
    font-size: 13vw;
    line-height: 0.6;
    letter-spacing: 0.3vw;
    font-family: 'Source Code Pro', Arial, sans-serif;
    font-weight: 700;
    bottom: 12%;
    left: 3%;
}

.home-text-background.part10 {
    font-size: 9vw;
    line-height: 1;
}

.home-text-background.part11 {
    font-size: 9vw;
    line-height: 1;
    margin-left: 37%;
}

.back-part-10-11 {
    left: 0;
    top: 35.5rem;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .home-text-background.part1 {
        margin-top: -6vw;
        font-size: 16vw;
    }

    .home-text-background.part2 {
        font-size: 10vw;
        margin-left: 24%;
        margin-top: 4%;
        width: 80%;
    }

    .back-part-3-4 {
        top: 6rem;
        text-align: right;
    }

    .home-text-background.part3 {
        font-size: 5vw;
    }

    .home-text-background.part4 {
        font-size: 9vw;
    }

    .home-text-background.part5 {
        font-size: 12vw;
        margin-left: 0;
        margin-top: 12rem;
    }

    .home-text-background.part6 {
        font-size: 12vw;
        margin-left: 50% !important;
    }

    .home-text-background.part7 {
        font-size: 9vw;
    }

    .home-text-background.part8 {
        font-size: 9vw;
    }

    .home-text-background.part9 {
        font-size: 9vw;
    }

    .home-text-background.part10 {
        font-size: 7vw;
        line-height: 1.2;
    }

    .home-text-background.part11 {
        font-size: 7vw;
        position: absolute;
    }

    .back-part-10-11 {
        left: 0;
        top: 34rem;
    }
}

@media (min-width: 3000px) {
    .home-text-background.part1 {
        font-size: 12vw;
    }

    .home-text-background.part2 {
        font-size: 6vw;
        margin-left: 30%;
        width: 80%;
    }

    .back-part-3-4 {
        top: 7rem;
        text-align: right;
    }

    .home-text-background.part3 {
        font-size: 4vw;
    }

    .home-text-background.part4 {
        font-size: 6.2vw;
    }

    .home-text-background.part5 {
        font-size: 9.6vw;
        margin-left: 0;
    }

    .home-text-background.part6 {
        font-size: 9.6vw;
        margin-left: 43% !important;
    }

    .back-part-5-6 {
        top: 14vw;
    }

    .home-text-background.part7 {
        font-size: 6vw;
    }

    .home-text-background.part8 {
        font-size: 6vw;
    }

    .home-text-background.part9 {
        font-size: 6vw;
    }

    .home-text-background.part10 {
        font-size: 5vw;
        line-height: 1.8;
    }

    .home-text-background.part11 {
        font-size: 5vw;
        position: absolute;
    }

    .back-part-10-11 {
        left: 0;
        top: 35rem;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .home-text-background.part1 {
        font-size: 13rem;
        margin-left: 1rem;
    }

    .home-text-background.part2 {
        font-size: 7rem;
        margin-left: 38%;
    }

    .back-part-3-4 {
        top: 3.5rem;
        margin-left: 0;
        width: fit-content !important;
    }

    .home-text-background.part3 {
        font-size: 7vw;
        margin-left: 46%;
        line-height: 0.5;
    }

    .home-text-background.part4 {
        font-size: 13vw;
    }

    .home-text-background.part5 {
        text-align: left;
        font-size: 12rem;
        margin-left: 0%;
    }

    .home-text-background.part6 {
        font-size: 12rem;
        margin-left: 5%;
    }

    .back-part-5-6 {
        top: 17rem;
        right: 1rem;
    }

    .home-text-background.part7 {
        font-size: 10rem;
        left: 2%;
    }

    .home-text-background.part8 {
        font-size: 10rem;
    }

    .home-text-background.part9 {
        font-size: 10rem;
    }

    .home-text-background.part10 {
        font-size: 14vw;
        line-height: 1;
        right: 0;
        position: absolute;
    }

    .home-text-background.part11 {
        font-size: 14vw;
        line-height: 1;
        left: 2rem;
        right: auto;
        margin-left: 0;
        top: 14vw;
        position: absolute;
    }

    .back-part-10-11 {
        left: 0;
        top: 44rem;
    }
}

@media (max-width: 992px) {
    .home-text-background.part1 {
        font-size: 11rem;
        margin-left: 1rem;
    }

    .home-text-background.part2 {
        font-size: 5rem;
        margin-top: 3rem;
        margin-left: 30%;
    }

    .back-part-3-4 {
        top: 10rem;
        text-align: right;
    }

    .home-text-background.part3 {
        font-size: 12vw;
    }

    .home-text-background.part4 {
        font-size: 20vw;
    }

    .home-text-background.part5 {
        text-align: left;
        font-size: 8rem;
        margin-left: 15%;
    }

    .home-text-background.part6 {
        font-size: 8rem;
        text-align: right;
        margin-left: 0;
    }

    .back-part-5-6 {
        top: 24rem;
        right: 1rem;
    }

    .home-text-background.part7 {
        font-size: 3.5rem;
        left: auto;
        top: 22rem;
        right: 4%;
    }

    .home-text-background.part8 {
        font-size: 3.5rem;
        top: calc(22rem + 3.5rem);
        right: 4%;
    }

    .home-text-background.part9 {
        font-size: 3.5rem;
        top: calc(22rem + 3.5rem + 3.5rem);
        left: auto;
        right: 4%;
    }

    .home-text-background.part10 {
        font-size: 14vw;
        line-height: 1;
        right: 0;
        position: absolute;
    }

    .home-text-background.part11 {
        font-size: 14vw;
        line-height: 1;
        left: 2rem;
        right: auto;
        margin-left: 0;
        top: 14vw;
        position: absolute;
    }

    .back-part-10-11 {
        left: 0;
        top: 44rem;
    }
}

@media (max-width: 576px) {
    .home-text-background.part1 {
        font-size: 4rem;
        margin-top: 1rem;
        letter-spacing: -0.2rem;
        margin-left: 19%;
    }

    .home-text-background.part2 {
        font-size: 3rem;
        margin-top: 1rem;
        margin-left: 19%;
        letter-spacing: -0.2rem;
    }

    .home-text-background.part5 {
        font-size: 5.5rem;
        text-align: left;
    }

    .home-text-background.part6 {
        font-size: 5.5rem;
    }

    .back-part-5-6 {
        top: 38rem;
        right: 1rem;
    }

    .home-text-background.part7 {
        top: 25rem;
    }

    .home-text-background.part8 {
        top: calc(25rem + 3.5rem);
    }

    .home-text-background.part9 {
        top: calc(25rem + 3.5rem + 3.5rem);
    }

    .back-part-10-11 {
        left: 0;
        top: 71rem;
    }
}

.section-platform {
    padding-top: 28vw !important;
    margin-bottom: 10rem;
}

.section-details {
    margin-bottom: 24rem;
}

.section-includes {
    margin-bottom: 15rem;
}

.section-join {
    margin-top: 8rem;
    height: 64rem;
    margin-left: -2%;
    margin-right: -2%;
}

.section-benefits {
    margin-top: 10rem;
    margin-bottom: 17rem;
}

.platform-p-row {
    padding-top: 4.5rem !important;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .section-platform {
        padding-top: 34vw !important;
    }

    .section-join {
        height: 80rem;
    }
}

@media (min-width: 3000px) {
    .section-platform {
        padding-top: 26vw !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .platform-p-row {
        padding-top: 2.5rem !important;
    }

    .section-platform {
        padding-top: 40vw !important;
    }
}

@media (min-width: 992px) {
    .details-second-row {
        margin-top: 10rem !important;
    }

    .fits-p-4 {
        padding-left: 2rem !important;
        padding-right: 1rem !important;
    }

    .fits-p-5 {
        padding-left: 1rem !important;
    }
}

@media (min-width: 2000px) {
    .fits-p-5 {
        padding-left: 0rem !important;
    }
}

@media (min-width: 576px) and (max-width: 992px) {
    .section-platform {
        padding-top: 92vh !important;
    }
}

@media (max-width: 992px) {
    .details-second-row {
        margin-top: 1rem !important;
    }

    .section-platform {
        margin-bottom: 5rem;
    }

    .section-details {
        margin-bottom: 26rem;
    }

    .section-includes {
        margin-bottom: 6rem;
    }

    .section-join {
        margin-top: 5rem;
        height: 35rem;
        margin-left: -4%;
        margin-right: -4%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .section-benefits {
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
}

@media (max-width: 768px) {
    .section-details {
        margin-bottom: 20rem;
    }
}

@media (max-width: 576px) {
    .section-platform {
        padding-top: 10rem !important;
    }

    .section-details {
        margin-bottom: 16rem;
    }

    .section-join {
        height: 40rem;
    }
}

@media (min-width: 576px) {
    .details-header-container {
        margin-left: 25%
    }
}

.home-block {
    background-color: var(--primary-color);
    z-index: 0;
}

.home-block .home-text-background {
    opacity: 0.25;

    &.animated {
        animation: back-0-25-anim var(--main-anim-duration) ease-in-out !important;
        opacity: 0.25 !important;
    }
}

@keyframes back-0-25-anim {
    from {
        opacity: 0
    }
    to {
        opacity: 0.25
    }
}

.includes-container {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.home-text-header-includes {
    margin-top: 2rem;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .home-text-header-includes {
        margin-top: 1rem;
        font-size: 2.8rem !important;
    }
}

@media (max-width: 992px) {
    .includes-container {
        width: 100%;
        height: fit-content;
        position: relative;
    }

    .home-text-header-includes {
        margin-top: 0rem;
        padding-bottom: 2rem;
    }
}

.home-block-1 {
    width: 55%;
    margin-left: 4%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3vw;
    row-gap: 3rem;
    column-gap: 4rem;
}

@media all and (max-width: 1200px) {
    .home-block-1 {
        grid-template-columns: 2fr 3fr;
        width: 67%;
        margin-left: 2%;
    }
}

@media all and (max-width: 992px) {
    .home-block-1 {
        margin-top: 2rem;
        grid-template-columns: 2fr 3fr;
        width: 100%;
        margin-left: 0%;
    }
}

@media (max-width: 576px) {
    .home-block-1 {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        padding: 2rem;
        margin-left: 0%;
        margin-right: 0%;
    }
}

.home-block-1-right {
    position: absolute;
    right: 0;
    height: 100%;
}

.home-block p,
.home-block label,
.home-block h1,
.home-block h2,
.home-block h3,
.home-block h4,
.home-block h5,
.home-block h6 {
    color: #ffffff !important;
}

.home-block .dash {
    background-color: #ffffff;
}

.home-block-1 .flex-row {
    gap: 3.3rem;
}

.p16-btn {
    color: var(--primary-color);
    font-weight: 700;
    background-color: #ffffff;
    width: fit-content;
    text-decoration: none;
    border: #ffffff;
    border-width: 1px;
    border-style: solid;
    padding: 0.5rem 2rem;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: var(--primary-color);
        color: #ffffff;
    }
}

@media (min-width: 3000px) {
    .p16-btn {
        font-size: 0.7vw;
    }
}

.asterisk-container {
    top: 10rem;
    right: 3%;
    opacity: 0;

    &.animated {
        animation: main-anim var(--main-anim-duration) ease-in-out;
        opacity: 1;
    }
}

.asterisk {
    width: 3.5rem;
    height: 3.5rem;
    animation: asterisk-anim 7s ease-in-out;
    animation-direction: normal;
    animation-iteration-count: infinite;
}

@media (max-width: 992px) {
    .asterisk-container {
        right: auto;
        left: 4%;
        top: 30rem;
    }

    .asterisk {
        width: 2.2rem;
        height: 2.2rem;
    }
}

@media (max-width: 576px) {
    .asterisk-container {
        top: 33rem;
    }
}

@keyframes asterisk-anim {
    0% {
        rotate: 0deg;
    }
    60% {
        rotate: 720deg;
    }
    100% {
        rotate: 360deg;
    }
}

.home-benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 4fr) 3fr 3fr;
    grid-template-rows: repeat(3, 22rem);
    gap: 2rem;
}

.benefit-plus-block {
    grid-row-start: 3;
    grid-column-start: 4;
    grid-column-end: span 2;
}

@media (min-width: 2000px) {
    .home-benefits-grid {
        grid-template-rows: repeat(3, 26rem);
    }
}

@media all and (min-width: 2000px) and (max-width: 3000px) {
    .home-benefits-grid {
        grid-template-columns: repeat(3, 4fr) 3fr 3fr;
    }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
    .home-benefits-grid {
        grid-template-columns: repeat(3, 2fr) 1fr;
        grid-template-rows: repeat(3, 18rem);
    }

    .benefit-plus-block {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-column-end: span 2;
    }
}

@media all and (max-width: 992px) {
    .home-benefits-grid {
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: repeat(4, 16rem);
        gap: 0.25rem;
        margin-right: -0.25rem;
    }

    .benefit-plus-block {
        grid-row-start: 4;
        grid-column-start: 2;
        grid-column-end: auto;
    }
}

@media all and (min-width: 576px) and (max-width: 992px) {
    .home-benefits-grid {
        grid-template-rows: repeat(4, 11rem);
    }
}

@media all and (max-width: 576px) {
    .home-benefits-grid {
        grid-template-rows: repeat(4, 11rem);
    }
}

.home-player-container {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 10;
}

.fits-first-row {
    gap: 6rem;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .fits-p-4 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .fits-first-row {
        gap: 1rem;
    }

    .fits-second-row {
        gap: 1rem;
    }

    .fits-p-5 {
        order: 2;
    }

    .fits-p-6 {
        order: 1;
        padding-left: 0 !important;
    }
}

@media (max-width: 992px) {
    .fits-first-row {
        gap: 2rem;
    }

    .fits-second-row {
        margin-top: 12rem !important;
        gap: 2rem;
    }
}

.join-us-header-container {
    top: 22rem;
}

.join-us-header {
    font-size: 5vw;
    letter-spacing: -0.1vw;
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .join-us-header {
        font-size: 3.5vw;
    }
}

@media (min-width: 3000px) {
    .join-us-header {
        font-size: 1.8vw;
        width: fit-content !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .join-us-header {
        font-size: 6vw;
    }
}

@media (max-width: 992px) {
    .join-us-header-container {
        top: 6rem;
        left: 15%;
    }

    .join-us-header {
        line-height: 1;
    }
}

@media (max-width: 576px) {
    .join-us-header-container {
        top: 6rem;
        left: 5%;
    }

    .join-us-header {
        font-size: 2.2rem;
    }
}

.benefit-block-4 {
    grid-row: 2;
    grid-column: 2;
}

.benefit-block-5 {
    grid-row: 2;
    grid-column: 3;
}

.benefit-block-6 {
    grid-row: 3;
    grid-column: 2;
}

@media (max-width: 992px) {
    .benefit-block-4 {
        grid-row: auto;
        grid-column: auto;
    }

    .benefit-block-5 {
        grid-row: auto;
        grid-column: auto;
    }

    .benefit-block-6 {
        grid-row: auto;
        grid-column: auto;
    }
}

.benefit-plus-block-inner {
    width: 55%;
}

.benefit-plus {
    width: 2.5rem;
    height: 2.5rem;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .benefit-plus-block-inner {
        width: 60%;
        margin-left: 40%
    }
}

@media (max-width: 992px) {
    .benefit-plus-block-inner {
        width: 100%;
        padding-left: 0.6rem;
        padding-bottom: 0.6rem;
    }

    .benefit-plus {
        width: 2.1rem;
        height: 2.1rem;
    }
}

.benefits-header-container {
    margin-bottom: 5rem;
}

@media (max-width: 992px) {
    .benefits-header-container {
        margin-bottom: 2rem;
    }
}

.algorithm-header {
    margin-bottom: 0 !important;
    line-height: 1;
}

.algorithm-second-row {
    margin-top: 6rem;
}

.algorithm-p-1 {
    width: 60%;
}

.algorithm-p-2 {
    width: 60%;
}

.algorithm-p-3 {
    width: 40%;
}

.algorithm-p-4 {
    width: 60%;
}

.algorithm-p-5 {
    width: 60%;
}

@media (max-width: 992px) {
    .algorithm-first-row {
        gap: 2rem;
        grid-template-columns: 1fr 1fr;
    }

    .algorithm-second-row {
        grid-template-columns: 1fr 1fr;
        margin-top: 2rem;
        gap: 2rem;
    }

    .algorithm-p-1 {
        width: 80%;
    }

    .algorithm-p-2 {
        width: 80%;
    }

    .algorithm-p-3 {
        width: 60%;
    }

    .algorithm-p-4 {
        width: 80%;
    }

    .algorithm-p-5 {
        width: 80%;
    }
}
