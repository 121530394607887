.m-menu-container {
    position: fixed;
    left: 100vw;
    top: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 999;
}

.m-menu-container.active {
    left: 0;
}

.m-menu-btn-close {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 9999px;
    background-color: #fff;
    border: 0;
}

.btn-cross {
    position: absolute;
    width: 80%;
    height: 0.1rem;
    background-color: var(--primary-color);
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    transform-origin: center;
    border-radius: 9999px;
}

.m-menu-btn {
    height: 3.2rem;
    width: 3.2rem;
    background-color: var(--primary-color);
    border-radius: 9999px;
    border: 0;
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 1.55;
    color: #fff;
    letter-spacing: 0.1rem;
    font-family: 'Arial', Arial, sans-serif;
}

.m-menu-nav {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-top: 3.5rem;
    margin-left: 2rem;

    .nav-link {
        letter-spacing: normal;
        font-size: 2rem !important;
    }
}

.m-menu-icons-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    bottom: 1.4rem;
    left: 1.4rem;
}

.m-menu-icon {
    width: 2rem;
    height: 2rem;
    background-color: var(--primary-color);
    border-radius: 9999px;
    border: 0;
}